<template>
  <div>
    <h1>404 Not Found</h1>
    
  </div>
</template>

<script>
export default {
  name: 'ErrorIndex',
  computed:{
  },
  methods:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
