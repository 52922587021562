<template>
  <v-app>
  
    <!-- 서비스 페이지 -->
    <div class="vhp-container">
      <v-main class="vhp-main">
        <router-view/>
      </v-main>

    </div>
    
  </v-app>
  </template>
  
  <script>
  
  
  export default {
    name: 'App',
    components: {
    },
    data() {
      return {
        scrollDown:false,
        scrollPosY:0,
      }
    },
  
    mounted() {
  
      this.setLanguage()
      //this.drawBackground()
    },
  
    unmounted() {
    },
  
    watch:{
      '$route'() {
        this.setLanguage()
  
      }
    },
    methods:{
      setLanguage() {
        if(this.$route.query.lang) {
          this.$utils.setCookie('vhp.locale', this.$route.query.lang, 7)
        }
        this.$i18n.locale = this.$utils.getCookie('vhp.locale') || 'kr'
      },
  
      drawBackground() {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
  
   
        canvas.width = window.innerWidth 
        canvas.height = document.body.offsetHeight
  
        ctx.font = '32px bold Apple SD Gothic Neo, Pretendard-Medium, NotoSansKR, sans-serif '
        ctx.textBaseline = 'middle'
        ctx.textAlign = 'center'
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
  
        ctx.fillStyle = "#C4182805";
        ctx.beginPath();
        ctx.moveTo(canvas.width * 0.6, 0);
        ctx.lineTo(canvas.width, 0);
        ctx.lineTo(canvas.width, canvas.height * 0.8);
        ctx.fill();
        
        ctx.fillStyle = "#C4182803";
        ctx.beginPath();
        ctx.moveTo(canvas.width * 0.6, canvas.height);
        ctx.lineTo(canvas.width, canvas.height);
        ctx.lineTo(canvas.width, canvas.height * 0.5);
        ctx.fill();
        let src = canvas.toDataURL('image/png', 1.0)
  
        document.body.style.backgroundImage = "url(" + src + ")";
      },
   
  
      onScrollUp() {
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top:0
      });
  
        setTimeout(() => {
          this.scrollDown = false
        },100)
      },

    }
  }
  </script>
  
  <style lang="scss">
  #app {
    text-align: center;
    color: $base-foreground-color;
    min-width: $min-width !important;
  }
  
  .vhp-container{
      width:100vw;
      -webkit-user-select:none;
      -moz-user-select:none;
      -ms-user-select:none;
      user-select:none;
  
          
      .vhp-main{
        display: flex;
          margin:0 auto;
          width:calc(100% - 32px);
          min-width: $min-width;
          max-width: $full-width;
          min-height:800px;
          padding: 0px 16px 0px 16px;

          @media screen and (max-width: #{$breakpoint-md}) {  
            width:100%; 
            padding: 0 0px 40px 0px;
          }
      }
  }
  </style>
  