import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js';
import axios from 'axios'
import global from './plugins/global.js'
import messages from './i18n'

import VueCryptojs from 'vue-cryptojs'
import AOS from 'aos'
import { createI18n } from 'vue-i18n'
import 'aos/dist/aos.css'


const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueCryptojs)
app.use(global)
app.use(AOS.init())
app.use(createI18n({
    locale:'kr',
    fallbackLocale:'en',
    messages
}))


axios.defaults.baseURL = process.env.baseURL
app.config.globalProperties.$axios = axios

app.mount('#app')


