import { createWebHistory, createRouter } from "vue-router";
import Index from "./views/index.vue";
import ErrorIndex  from "./views/error/index.vue";

const DEFAULT_TITLE = '경호 ♡ 은진 청첩장';

const routes = [
  {
    path: "/",
    component: Index,
    name: "홈"
  },{
    path: "/error",
    component: ErrorIndex,
    children:[],
    meta: {
      title:'오류'
    }
  },{
    path: '/:pathMatch(.*)*',
    redirect: "/error"
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(() => {
  if(location.pathname !== '/') {
    window.scrollTo(0, 0)
  }
})



router.afterEach((to)=> {
  document.title = (to.meta.title ? to.meta.title + ' | ' : '') + DEFAULT_TITLE
})

export default router;