<template>
  <div class="content-container">
    <section class="main-section">
      <div class="flower one">

      </div>
      <div class="flower two">

      </div>
      <div class="d-day">
        <div class="text">
          Wedding Day
        </div>

          D - {{ dDay }}
      </div>
      <div class="image-cover">
        <img class="image main" :src="require('@/assets/image/main.jpg')" />
      </div>

      <div class="main-content">
        <p class="content">
          <span class="name"> 
            박 경 호 
            <span class="silver" style="font-size:0.7rem;">&</span> 
            이 은 진 </span>
        </p>
        <br/>
        <p class="content date">2025. 06. 07 토요일 오후 1시</p>
        <p class="content date">센텀사이언스파크 23층 스카이홀</p>
      </div>
    </section>

    <section>
      <div class="content letter">
        <h3>초대합니다</h3>

        <span class="write">
          서로가 마주보며 다져온 사랑을 이제 함께 한 곳을 바라보며 걸어갈 수
          있는 큰 사랑으로 키우고자 합니다. 저희 두 사람이 사랑의 이름으로
          지켜나갈 수 있도록 앞날을 축복해 주시면 감사하겠습니다.
        </span>

        <div class="content honju">
          <div class="divider"></div>

          <p class="r">
            <span class="parent">박규식</span> <span class="dot" />
            <span class="parent">김진분</span>의 아들
            <span class="hero">경호</span>
          </p>

          <p class="r">
            <span class="parent">이정범</span> <span class="dot" />
            <span class="parent">김미경</span>의 &nbsp;&nbsp;&nbsp;딸
            <span class="hero">은진</span>
          </p>
        </div>
      </div>
    </section>

    <section></section>


    <section>
      <div class="content contact">
        <div class="hero">
          신랑
          <div>
            <span class="mdi mdi-phone-outline man mx-1"></span>

            <span class="mdi mdi-email-outline mx-1"></span>
          </div>

        </div>

        <div class="hero">
          신부

          <div>
            <span class="mdi mdi-phone-outline woman mx-1"></span>

            <span class="mdi mdi-email-outline mx-1"></span>
          </div>

        </div>

      </div>
     
      <div class="content divider"
      >
        <p>혼주에게 연락하기</p>
      </div>

      <div class="content row">
        <div class="content col">
          <p class="my-4">신랑측 혼주</p>

          <p>아버지</p>
          <div class="content row my-3">
            <span class="mdi mdi-phone-outline man"></span>
            <span class="mdi mdi-email-outline"></span>
          </div>

          <p>어머니</p>
          <div class="content row my-3">
            <span class="mdi mdi-phone-outline man"></span>
            <span class="mdi mdi-email-outline"></span>
          </div>
        </div>

        <div class="content col">
          <p class="my-4">신부측 혼주</p>

          <p>아버지</p>
          <div class="content row my-3">
            <span class="mdi mdi-phone-outline woman"></span>
            <span class="mdi mdi-email-outline"></span>
          </div>

          <p>어머니</p>
          <div class="content row my-3">
            <span class="mdi mdi-phone-outline woman"></span>
            <span class="mdi mdi-email-outline"></span>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div
        class="ui-datepicker "
      >
        <div
          class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all"
        >
          <div class="ui-datepicker-title">
            <span class="ui-datepicker-month"><strong>6</strong> June</span
            >&nbsp;<span class="ui-datepicker-year">2025</span>
          </div>
        </div>

        <table class="ui-datepicker-calendar">
          <tbody>
            <tr>
              <td
                class="ui-datepicker-week-end"
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">1</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">2</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">3</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">4</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">5</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">6</a>
              </td>
              <td
                class="ui-datepicker-week-end sat"
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default ui-state-active" href="#">7</a>
              </td>
            </tr>
            <tr>
              <td
                class="ui-datepicker-week-end"
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">8</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">9</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">10</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">11</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">12</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">13</a>
              </td>
              <td
                class="ui-datepicker-week-end sat"
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">14</a>
              </td>
            </tr>
            <tr>
              <td
                class="ui-datepicker-week-end"
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">15</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">16</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">17</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">18</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">19</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">20</a>
              </td>
              <td
                class="ui-datepicker-week-end sat"
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">21</a>
              </td>
            </tr>
            <tr>
              <td
                class="ui-datepicker-week-end"
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">22</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">23</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">24</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">25</a>
              </td>
              <td
                class="ui-datepicker-days-cell-over ui-datepicker-current-day"
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a
                  class="ui-state-default"
                  href="#"
                  >26</a
                >
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">27</a>
              </td>
              <td
                class="ui-datepicker-week-end sat"
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">28</a>
              </td>
            </tr>
            <tr>
              <td
                class="ui-datepicker-week-end"
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">29</a>
              </td>
              <td
                class=" "
                data-handler="selectDay"
                data-event="click"
                data-month="8"
                data-year="2025"
              >
                <a class="ui-state-default" href="#">30</a>
              </td>
              <td
                class="ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled"
              >
                &nbsp;
              </td>
              <td
                class="ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled"
              >
                &nbsp;
              </td>
              <td
                class="ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled"
              >
                &nbsp;
              </td>
              <td
                class="ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled"
              >
                &nbsp;
              </td>
              <td
                class="ui-datepicker-week-end ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled"
              >
                &nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section>
      <p class="title">사진</p>


      <div class="content gallery">
        <img
          class="image sub"
          v-for="(image, idx) in images"
          :key="'image_' + idx"
          :src="'/image/' + image"
        />
      </div>
    </section>

    <section>
      <p class="title">동영상</p>
      <iframe
        width="100%"
        height="215"
        src="https://www.youtube.com/embed/SbydDGe8cTE?si=KGMt41W2MyfRozZf"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </section>

    <section>
      
      <div class="content location">
        <p class="title">오시는 길</p>

        <div class="hall">
          <strong>
            센텀사이언스파크 더 스카이홀 23F
          </strong>
          <br/>
          부산 해운대구 센텀중앙로 79
          <br/>
          Tel. 051-711-7755

        </div>
       

          <img class="image" :src="require('@/assets/image/map.png')" />


        <div class="location-app">
          <a href="https://naver.me/GC2uv8cl" target="_blank" class="link navermap">네이버지도</a>
          <a href="https://place.map.kakao.com/14525753" target="_blank" class="link kakaomap">카카오맵</a>
        </div>


        <div class="info">
          <p class="h">지하철 안내</p>
          <p>2호선 센텀시티역 하차 4번/6번 출구 이용(800m 도보 13분 가량 소요)</p>

          <p class="h">버스 안내</p>
          <p>SK텔레콤 정류장 하차 : 115, 181, 307</p>
          <p>센텀시티역 벡스코 하차 : 5-1, 39, 40, 63, 141, 155, 1001, 1002, 107</p>

          <p class="h">셔틀버스 운행</p>
          <p>센텀시티역 4번 출구앞에서 센텀사이언스파크 정문까지 셔틀 운행 (15분 간격)</p>

          <p class="h">주차 안내</p>
          <p>본관 지하주차장(400대)</p>
          <p>KNN 주차장(걸어서 5분, 600대)</p>
          <p class="caption">*주차 3시간 지원</p>
          
          <p class="h">피로연 안내</p>
          <p>지하 1층 더파티 뷔페</p>
        </div>


      </div>
      <div class="content divider"></div>

      
    </section>



    <section>

      <p>마음전하기</p>
    </section>

    <section>
      <p>댓글</p>
    </section>

    <section>
      <p>공유하기</p>
    </section>
  </div>
</template>

<script>
export default {
  name: "ContentIndex",
  data() {
    return {
      images: [
        "001.jpeg",
        "002.jpeg",
        "003.jpeg",
        "004.jpeg",
        "005.jpeg",
        "006.jpeg",
        "007.jpeg",
        "008.jpeg",
        "009.jpeg",
        "010.jpeg",
        "011.jpeg",
        "012.jpeg",
      ],
      dDay:0
    };
  },

  mounted() {
    this.calculateDDay()
  },

  methods: {
    calculateDDay() {
      const targetDate = new Date(Date.UTC(2025, 5, 7) + (9 * 60 * 60 * 1000));

      // 현재 날짜를 UTC로 가져온 후, 한국 시간대를 반영하여 조정
      const now = new Date();
      const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()) + (9 * 60 * 60 * 1000));

      // D-Day 계산: 목표 날짜 - 오늘 날짜 (결과는 밀리초 단위)
      const diff = targetDate - today;

      // 밀리초를 일(day) 단위로 변경
      const day = 1000 * 60 * 60 * 24;

      // D-Day 계산 결과
      this.dDay = Math.ceil(diff / day);
    }
  },
};
</script>

<style lang="scss">
.content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;

  * {
    max-width: 100%;
  }
  p {
    margin: 0;
  }

  section {
    min-width: 320px;
    margin:24px 0;
    

    &.main-section {
      max-height: 100vh;
      width: 100%;
      max-width: 100%;
      margin:0;
      position: relative;
      overflow: hidden;

      @media only screen and (max-width: 600px) {
        height: 100vh;
      }
      .flower{
        position: absolute;

        &.one{

          right:-24px;
          top:-24px;
          background-image: url('@/assets/image/flower1.png');
          width:128px;
          height: 128px;
        }
        &.two{
          left: -12px;
          top: 60vh;
          transform: rotateZ(90deg);
          z-index: 10;
          background-image: url('@/assets/image/flower2.png');
          width:128px;
          height: 128px;
        }
      }

      .d-day{
        position: absolute;
        left:50%;
        transform: translateX(-50%);
        top:4.5vh;
        display: flex;
        flex-direction: column;
        .text{
          text-transform: uppercase;
          letter-spacing: 0.2rem;
          font-size: 1vh;
          color: silver;
          margin-bottom: 6px;
        }
        font-size: 2.3vh;
      }
    }

    .main-content {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      flex-direction: column;
      padding: 0 2rem;
      box-sizing: border-box;

     

     
    }
  }

  .title{
    font-size: 1.1rem;
    margin:24px 0;
  }

  .content {
    display: flex;
    flex-grow: 1;
    justify-content: center;

    iframe {
        overflow: hidden;
        max-width: 100%;
        width:100%;
        aspect-ratio: 3 / 4 !important;
        object-fit: contain;
      }

    &.title {
      font-size: 3rem;
    }

    &.date {
      font-size: 0.8rem;
      font-family: "NanumMyeongjo-Regular" !important;
      line-height: 1.7;
    }

    &.letter {
      padding: 10vh 48px;
      font-size: 0.95rem;
      white-space: pre-wrap;
      background-color: rgba(beige, 0.2);
      line-height: 2;
      flex-direction: column;
      .write {
        margin-top: 24px;
        font-family: "KyoboHandwriting2020A", system-ui, -apple-system,
          BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
          "Open Sans", "Helvetica Neue", sans-serif !important;
      }

      h3{
        color: silver !important;
        font-weight: normal;
      }
    }

    &.location{
      flex-direction: column;
      padding: 10vh 0;

      .hall {
        white-space: pre-wrap;
        flex-direction: column;
        margin:12px 0;

        strong{
          font-size: 1rem;
          line-height: 2;
        }
      }

      .info{
        text-align: start;
        margin:16px;
        line-height: 1.7;
        font-size: 0.8rem;
        .h{
          margin-top:21px;
          font-size: 0.9rem;
          font-family: 'NanumMyeongjo-Bold' !important;
        }
      }

      
      .location-app{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: rgba(silver, 0.1);
        padding:12px 0;

        .link {
          position: relative;
          display: block;
          padding-top: 3.438rem;
          font-size: 0.688rem;
          font-weight: 400;
          line-height: 1rem;
          color: #111;
          flex: 1 1 auto;

          &:first-of-type{
            border-right: 1px solid rgba(silver,0.5);
          }

        }

        .tmap {
          &::after {
            content: "";
            background-image: url("@/assets/image/tmap.png");
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -1.563rem;
            width: 3.125rem;
            height: 3.125rem;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 3.125rem;
          }
        }

        .navermap {
          &::after {
            content: "";
            background-image: url("@/assets/image/navermap.png");
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -1.563rem;
            width: 3.125rem;
            height: 3.125rem;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 3.125rem;
          }
        }

        .kakaonavi {
          &::after {
            content: "";
            background-image: url("@/assets/image/kakaonavi.png");
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -1.563rem;
            width: 3.125rem;
            height: 3.125rem;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 3.125rem;
          }
        }

        .kakaomap {
          &::after {
            content: "";
            background-image: url("@/assets/image/kakaomap.png");
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -1.563rem;
            width: 3.125rem;
            height: 3.125rem;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 3.125rem;
          }
        }
      }
    }

    &.contact {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;


      .hero{
        flex: 1 1 calc(50% - 24px);
        flex-direction: column;
      }
    }

    &.divider{
      padding:12px 0;
      margin:32px 0;
      background-color: rgba(silver, 0.15);
    }

    &.honju {
      font-size: 0.7rem;
      line-height: 2;
      display: flex;
      flex-direction: column;

      .divider {
        width: 0.5px;
        height: 48px;
        background-color: silver;
        opacity: 0.8;
        margin: 36px auto;
      }

      .parent {
        font-size: 0.95rem;
      }

      .hero {
        font-size: 1.2rem;
        margin-left: 8px;
      }

      .dot {
        position: relative;
        display: inline-block;
        height: 24px;
        margin: 0 6px;
        &::after {
          content: ".";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          display: block;
          font-size: 0.813rem;
          font-weight: 400;
          line-height: 1;
          text-align: center;
        }
      }

      .r {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }


    .name {
      letter-spacing: 0.2rem;
      font-size: 1rem;
      margin: 24px 0 0 0;
      line-height: 2;
    }

    .info{
      font-size: 0.9rem;
    }
  }

  .image-cover {
    aspect-ratio: 1 !important;
    position: relative;
    max-height: 55vh;
    margin: 0 auto;
    margin-top: 15vh;

  }

  .image {
    &.main {
      margin: 0 12px;
      border-top-left-radius: 18rem;
      border-top-right-radius: 18rem;
      max-width: calc(100% - 24px);
      height: 100%;
      object-fit: cover;
      box-shadow: 0px 4px 24px rgba(silver, 0.5);
      margin: 0 12px 0 12px;

    }

    &.sub {
      max-width: calc(25% - 4px);
      width: calc(25% - 4px);
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 0.5rem;
    }
  }

  .gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    img{
      padding:2px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
  }

  .col {
    display: flex;
    flex-direction: column;
  }



}

.ui-datepicker{
  display: flex;
  flex-direction: column;
  margin:0 24px;
  padding:18px;
  .ui-datepicker-title{
    margin-bottom:12px;
  }

  .ui-datepicker-calendar{
    .ui-state-default{
      font-size: 0.7rem;
    }

    .ui-datepicker-week-end{
      .ui-state-default{
        color:red;
      }

      &.sat{
        .ui-state-default{
          color:silver;
        }
      }
    }

    .ui-state-active{
      position: relative;
      color: #fff !important;
      font-family: 'NanumMyeongjo-Bold';
      &::after{
        z-index: -1;
        content: "";
        position: absolute;
        left:-10.5px;
        top:-8px;
        width:28px;
        height:28px;
        background-color:#FF80AB;
        border-radius: 100%;
      }
    }
  }



 
}

.silver{
  color: silver;
}

.mdi {
  width:36px;
  height:36px;
  font-size: 18px;
  background-color: rgba(silver, 0.5);
  color: white;

  &.mdi-phone-outline{
    border-radius: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.mdi-email-outline{
    border-radius: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.man {
    background-color: cyan;
  }

  &.woman{
    background-color: pink;
  }
}

.caption {
  font-size: 0.7rem;
  font-weight: bold;
}
</style>
