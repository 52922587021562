import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  plugins: [createPersistedState()],
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
  },
});